<template>
	<Dialog :dialog="checkoutDialog" :dialogWidth="dialogWidth">
		<template v-slot:title>
			<v-layout>
				<v-flex>Select Issue</v-flex>
				<v-flex class="text-right">
					<v-btn
						icon
						:disabled="dataLoading"
						class="mx-2 custom-grey-border custom-bold-button"
						v-on:click="$emit('close', true)"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<template v-slot:body>
			<v-container fluid>
				<!-- Search Input Box -->
				<div style="width: 647px; margin-left: 16px">
					<v-row>
						<v-col md="12">
							<v-text-field
								v-model="searchQuery"
								placeholder="Search by Member Name or Barcode"
								append-icon="mdi-magnify"
								clearable
								dense
								outlined
								hide-details
							></v-text-field>
						</v-col>
					</v-row>
				</div>

				<!-- Checkout List -->
				<v-row>
					<v-col md="12">
						<perfect-scrollbar
							:options="{ suppressScrollX: true }"
							class="scroll"
							style="max-height: 80vh; position: static"
						>
							<template v-if="filteredCheckoutList.length > 0">
								<v-list
									class="mx-2 mx-md-4 my-2 border border-secondary"
									v-for="(data, index) in filteredCheckoutList"
									:key="index"
									subheader
									three-line
								>
									<v-skeleton-loader
										v-if="dataLoading"
										class="custom-skeleton"
										type="list-item-avatar-two-line"
									>
									</v-skeleton-loader>
									<template v-else>
										<v-list-item v-on:click="selectCheckout(data)" link>
											<v-list-item-avatar>
												<ImageTemplate circle :src="getImagePath(data)"></ImageTemplate>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title class="font-size-16">
													<Chip :text="data.member_relation.barcode"></Chip>
												</v-list-item-title>
												<v-list-item-subtitle class="font-size-14">
													<b>{{ data.member_relation.display_name }}</b>
												</v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-content>
												<v-list-item-title class="font-size-16">
													<b>Return On :</b> {{ formatDate(data.return_on) }}
												</v-list-item-title>
											</v-list-item-content>
											<v-list-item-action class="margin-auto">
												<v-list-item-action-text class="font-size-16"
													><b>Total Quantity</b> - {{ data.quantity }}
												</v-list-item-action-text>
												<v-list-item-action-text class="font-size-16"
													><b>Remaining Quantity</b> - {{ data.remaining_quantity }}
												</v-list-item-action-text>
											</v-list-item-action>
										</v-list-item>
									</template>
								</v-list>
							</template>
							<template v-else>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-size-16">No Issue(s) Found </v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</template>
						</perfect-scrollbar>
					</v-col>
				</v-row>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				class="mx-2 custom-grey-border custom-bold-button"
				color="cyan white--text"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import { GetCheckoutList } from "@/core/lib/checked-out.lib";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";

export default {
	data() {
		return {
			dataLoading: false,
			checkoutList: [],
			searchQuery: "", // Search query to filter items
		};
	},
	props: {
		checkoutDialog: {
			type: Boolean,
			default: false,
		},
		dialogWidth: {
			type: Number,
			default: 768,
		},
		assetStockUuid: {
			type: String,
			default: null,
		},
	},
	components: {
		Dialog,
		Chip,
		ImageTemplate,
	},
	watch: {
		checkoutDialog: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.assetStockCheckout();
				}
			},
		},
	},
	methods: {
		assetStockCheckout() {
			const _this = this;
			this.dataLoading = true;
			GetCheckoutList(this.assetStockUuid)
				.then((data) => {
					_this.checkoutList = data;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.dataLoading = false;
				});
		},
		getImagePath(data) {
			if (data.member_relation.image) {
				return data.member_relation.image;
			}
			return process.env.VUE_APP_BASE_URL + "/media/misc/no_photo_found.png";
		},
		selectCheckout(data) {
			this.$emit("selectCheckout", {
				id: data.id,
				uuid: data.uuid,
			});
		},
	},
	computed: {
		filteredCheckoutList() {
			return this.checkoutList.filter((item) => {
				const searchString = this.searchQuery.toLowerCase();
				return (
					item.member_relation.display_name.toLowerCase().includes(searchString) ||
					item.member_relation.barcode.toLowerCase().includes(searchString)
				);
			});
		},
	},
};
</script>
