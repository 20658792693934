<template>
	<v-layout>
		<Dialog :dialog="dialog" :dialogWidth="dialogWidth">
			<template v-slot:title>
				<v-layout>
					<v-flex> Manage Supplier </v-flex>
					<v-flex class="text-right">
						<!-- <v-btn
							tile
							depressed
							color="blue darken-4 text-white"
							small
							class="mx-4"
							v-on:click="createContractType"
						>
							<span class="font-size-14 font-weight-600"> <v-icon>mdi-plus</v-icon> New Supplier</span>
						</v-btn> -->
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<v-container class="py-0">
					<v-form
						ref="categoryForm"
						v-model="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateContractSetting"
					>
						<v-row class="py-0">
							<v-col md="3" class="my-auto py-0">
								<label for="company-name" class="btx-label mt-2 required">Company Name</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextInput
									hide-details
									id="company-name"
									placeholder="Company Name"
									:rules="[vrules.required(contact.company_name, 'Company Name')]"
									:class="{
										required: !contact.company_name,
									}"
									v-model="contact.company_name"
								></TextInput>
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label for="display-name" class="btx-label mt-2 required"
									>Display Name
									<TooltipQuestion>
										<template v-slot:text
											>This name will be displayed on<br />the transactions you create for<br />this
											supplier</template
										>
									</TooltipQuestion>
								</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextInput
									hide-details
									id="display-name"
									:rules="[vrules.required(contact.display_name, 'Display Name')]"
									:class="{
										required: !contact.display_name,
									}"
									placeholder="Display Name"
									v-model="contact.display_name"
								></TextInput>
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label for="display-name" class="btx-label mt-2">UEN </label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextInput
									hide-details
									id="display-name"
									placeholder="UEN Number"
									v-model="contact.uen"
								></TextInput>
							</v-col>
							<template v-if="!uuid">
								<v-col md="3" class="my-auto py-0">
									<label for="contact-email" class="btx-label mt-2 required">Email Address</label>
								</v-col>
								<v-col md="9" class="py-0">
									<EmailInput
										hide-details
										id="contact-email"
										placeholder="Email Address"
										v-model="contact.email"
										:rules="[vrules.required(contact.email, 'Email Address')]"
										:class="{
											required: !contact.email,
										}"
									></EmailInput>
								</v-col>
							</template>
							<v-col md="3" class="my-auto py-0">
								<label for="contact-phone-no" class="btx-label mt-2 required">Phone</label>
							</v-col>
							<v-col md="9" class="py-0">
								<PhoneTemplate
									hide-details
									id="contact-phone-no"
									placeholder="Phone No."
									v-model="contact.phone_no"
									:rules="[vrules.required(contact.phone_no, 'Phone No.')]"
									:class="{
										required: !contact.phone_no,
									}"
								></PhoneTemplate>
							</v-col>
							<v-col md="12" class="pb-0">
								<label class="btx-label mt-2">BILLING ADDRESS</label>
								<hr class="mt-0" />
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label :for="`address-postalcode`" class="btx-label mt-2 required">Postal Code</label>
							</v-col>
							<v-col md="9" class="py-0">
								<PostalCodeTemplate
									hide-details
									:id="`address-postalcode`"
									placeholder="Postal Code"
									v-model="address.address_postal_code"
								></PostalCodeTemplate>
							</v-col>
							<v-col md="3" class="mt-2 py-0">
								<label :for="`address-street-1`" class="btx-label mt-2 required">Address</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextAreaInput
									hide-details
									:id="`address-street-1`"
									placeholder="Street 1"
									v-model="address.address_line_1"
									:rules="[vrules.required(address.address_line_1, 'Address')]"
								></TextAreaInput>
							</v-col>
							<v-col md="12" class="pb-0">
								<label class="btx-label mt-2">Contact Person</label>
								<hr class="mt-0" />
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label :for="`address-postalcode`" class="btx-label mt-2 required">Salutation</label>
							</v-col>
							<v-col md="9" class="py-0">
								<SelectInput
									hide-details
									:items.sync="listTitle"
									id="salutation"
									placeholder="Title"
									v-model.trim="contactPerson.title"
								></SelectInput>
							</v-col>

							<v-col md="3" class="mt-2 py-0">
								<label :for="`address-street-1`" class="btx-label mt-2 required">First Name</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextInput
									hide-details
									placeholder="First Name"
									v-model.trim="contactPerson.first_name"
									:rules="[
										vrules.required(contactPerson.first_name, 'first name'),
										vrules.minLength(contactPerson.first_name, 'first name', 2),
										vrules.maxLength(contactPerson.first_name, 'first name', 100),
									]"
									:class="{
										required: !contactPerson.first_name,
									}"
								></TextInput>
							</v-col>
							<v-col md="3" class="mt-2 py-0">
								<label :for="`address-street-1`" class="btx-label mt-2 required">Last Name</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextInput
									hide-details
									placeholder="Last Name"
									v-model.trim="contactPerson.last_name"
									:rules="[
										vrules.required(contactPerson.last_name, 'Last name'),
										vrules.minLength(contactPerson.last_name, 'Last name', 2),
										vrules.maxLength(contactPerson.last_name, 'Last name', 100),
									]"
									:class="{
										required: !contactPerson.last_name,
									}"
								></TextInput>
							</v-col>
							<v-col md="3" class="mt-2 py-0">
								<label :for="`address-street-1`" class="btx-label mt-2 required">Display Name</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextInput
									hide-details
									placeholder="Display Name"
									v-model.trim="contactPerson.display_name"
									:rules="[
										vrules.required(contactPerson.display_name, 'display name'),
										vrules.minLength(contactPerson.display_name, 'display name', 2),
										vrules.maxLength(contactPerson.display_name, 'display name', 100),
									]"
									:class="{
										required: !contactPerson.display_name,
									}"
									v-on:keyup="appendDisplayName()"
								></TextInput>
							</v-col>
							<v-col md="3" class="mt-2 py-0">
								<label :for="`address-street-1`" class="btx-label mt-2 required">Phone Number</label>
							</v-col>
							<v-col md="9" class="py-0">
								<PhoneTemplate
									hide-details
									:rules="[vrules.required(contactPerson.mobile, 'Mobile')]"
									:class="{
										required: !contactPerson.mobile,
									}"
									id="contactperson-fax"
									placeholder="Phone Number"
									v-model="contactPerson.mobile"
								></PhoneTemplate>
							</v-col>
							<v-col md="3" class="mt-2 py-0">
								<label :for="`address-street-1`" class="btx-label mt-2 required">Email</label>
							</v-col>
							<v-col md="9" class="py-0">
								<EmailInput
									hide-details
									:id="`person-email`"
									placeholder="Email Id"
									v-model.trim="contactPerson.email"
									:class="{
										required: !contactPerson.email,
									}"
									:rules="[vrules.required(contactPerson.email, 'Email')]"
								></EmailInput>
							</v-col>
							<v-col md="3" class="mt-2 py-0">
								<label :for="`address-street-1`" class="btx-label mt-2 required">Designation</label>
							</v-col>
							<v-col md="9" class="py-0">
								<SelectInput
									hide-details
									append-outer-icon="mdi-cog"
									:items.sync="designationLists"
									placeholder="Select Designation"
									item-text="value"
									item-value="value"
									:id="`designation`"
									v-on:click:append-outer="manageDesignationDialog = true"
									v-model="contactPerson.designation"
									:class="{
										required: !contactPerson.designation,
									}"
									:rules="[vrules.required(contactPerson.designation, 'Designation')]"
								></SelectInput>
							</v-col>
							<!-- 	<v-col cols="12">
								<ContactPerson
									:detail="contact"
									v-on:saveContactPerson="updateContactPerson"
									:update-contact="updateSupplier"
									:default-contact-persons="contact_person"
								>
								</ContactPerson>
							</v-col> -->
						</v-row>
					</v-form>
				</v-container>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					class="mx-2 custom-grey-border custom-bold-button white--text"
					color="blue darken-4 white--text"
					v-on:click="updateOrCreate"
				>
					Save
				</v-btn>
				<v-btn
					tile
					depressed
					class="mx-2 custom-grey-border custom-bold-button"
					v-on:click="$emit('close-dialog', true)"
				>
					Close
				</v-btn>
			</template>
		</Dialog>
		<template v-if="manageDesignationDialog">
			<ManageDesignation
				:dialog="manageDesignationDialog"
				:designation="designationLists"
				v-on:close-dialog="manageDesignationDialog = false"
				v-on:get-designation="getDesignations"
			></ManageDesignation>
		</template>
	</v-layout>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import { toSafeInteger } from "lodash";
import PhoneTemplate from "@/view/components/Phone";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import ManageDesignation from "@/view/module/contacts/Manage-Designation.vue";
import PostalCodeTemplate from "@/view/components/PostalCode";
import ApiService from "@/core/services/api.service";

export default {
	props: {
		dialog: {
			type: Boolean,
			required: true,
			default: false,
		},
		category: {
			type: Array,
			default: () => {
				return new Array();
			},
		},
	},
	data: () => {
		return {
			formValid: true,

			categoryList: [],
			designationLists: [],
			deleteURL: null,
			deleteId: null,
			deleteDialog: false,
			deleteIndex: null,
			deleteText: null,
			uuid: null,
			manageDesignationDialog: false,

			contact: {
				id: null,
				uuid: null,
				title: "mr",
				first_name: null,
				last_name: null,
				uen: null,
				company_name: null,
				image: null,
				designation: null,
				display_name: null,
				email: null,
				phone_no: null,
				fax: null,
				website: null,
				remark: null,
			},
			contactPerson: {
				id: null,
				title: "mr",
				first_name: null,
				last_name: null,
				display_name: null,
				email: null,
				mobile: null,
				designation: null,
			},
			address: {
				id: null,
				uuid: null,
				name: null,
				id_number: null,
				contact_person: 0,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
		};
	},
	methods: {
		async updateOrCreate() {
			const formData = {
				contact: this.contact,
				persons: [this.contactPerson],
				address: [this.address],
			};

			ApiService.post(`supplier`, formData)
				.then(({ data }) => {
					console.log(data);
					this.$emit("close-dialog", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				});
			/* .finally(() => {
					this.pageLoading = false;
				}); */
		},
		createContractType() {
			console.log("createContractType");
		},
		updateContractSetting() {
			console.log("updateContractSetting");
		},
		getDesignations() {
			ApiService.setHeader();
			ApiService.get(`contact-options`)
				.then(({ data }) => {
					this.designationLists = data.designation;
				})
				.catch((error) => {
					this.logError(error);
				});
			/* .finally(() => {
					this.pageLoading = false;
				}); */
		},
	},
	components: {
		Dialog,
		PhoneTemplate,
		EmailInput,
		TextInput,
		TooltipQuestion,
		PostalCodeTemplate,
		TextAreaInput,
		//ContactPerson,
		ManageDesignation,
		SelectInput,
	},
	mounted() {
		this.categoryList = this.localDB("groups", []);
		this.getDesignations();
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
