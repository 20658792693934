<template>
<div>
    <Dialog :dialog="stockDialog" :dialog-width="800">
        <template v-slot:title>Add Stock</template>
        <template v-slot:body>
            <v-form ref="stockForm" v-model="formValid" lazy-validation v-on:submit.stop.prevent="addStock">
                <v-row class="stock-dialog">
                    <v-col md="3" class="my-auto py-0">
                        <label for="supplier" class="btx-label mt-3">Supplier</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                        <AutoCompleteInput hide-details :items="supplierList" :disabled="pageLoading" :loading="pageLoading" id="supplier" placeholder="Supplier" v-model="stock.supplier" @click:append-outer="manageSupplierDialog = true" append-outer-icon="mdi-cog" />
                    </v-col>

                    <v-col md="3" class="my-auto py-0">
                        <label for="quantity" class="btx-label mt-3">Quantity</label>
                    </v-col>
                    <v-col md="9" class="py-0 mt-3">
                        <v-text-field hide-details type="number" placeholder="Enter Quantity" v-model="stock.quantity" required outlined id="quantity" :disabled="pageLoading" />
                    </v-col>

                    <v-col md="3" class="py-0">
                        <label for="comments" class="btx-label mt-3">Description</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                        <TextAreaInput hide-details :disabled="pageLoading" :loading="pageLoading" id="comments" placeholder="commentss (max 200 characters)" v-model="stock.description" counter="200" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:action>
            <v-btn class="white--text" :loading="pageLoading" :disabled="!formValid || pageLoading" depressed color="blue darken-4" tile v-on:click="addStock">
                Add
            </v-btn>
            <v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)">
                Close
            </v-btn>
        </template>
    </Dialog>

    <template v-if="manageSupplierDialog">
        <ManageSupplier :dialog="manageSupplierDialog" :category="categoryList" v-on:close-dialog="manageSupplierDialog = false" v-on:success="getCategories" />
    </template>
</div>
</template>

  
  
<script>
import {
    mapGetters
} from "vuex";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import ManageSupplier from "@/view/components/Manage-Supplier.vue";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
    name: "stock-template",
    title: "stock Template",
    props: {
        stockDialog: {
            type: Boolean,
            default: false,
        },
        typeUuid: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            formValid: true,
            pageLoading: false,
            supplierList: [],
            manageSupplierDialog: false,
            stock: {
                supplier: null,
                quantity: null,
                description: null,
            },
        };
    },
    watch: {
        stockDialog(param) {
            if (param) {
                this.init();
            }
        },
    },
    components: {
        Dialog,
        AutoCompleteInput,
        TextAreaInput,
        ManageSupplier,
    },
    methods: {
        init() {
            this.stock = {
                supplier: null,
                quantity: null,
                description: null,
            };
        },
        addStock() {
            const formErrors = this.validateForm(this.$refs.stockForm);
            if (formErrors.length) {
                this.$store.commit('SET_ERROR', formErrors);
                return false;
            }

            if (!this.$refs.stockForm.validate()) {
                return false;
            }

            const cartData = {
                supplier: this.stock.supplier,
                quantity: this.stock.quantity,
                description: this.stock.description,
            };
            this.pageLoading = true;
            ApiService.post(`asset-stock/${this.typeUuid}/add`, cartData)
                .then(() => {
                    this.$emit("success");
                    this.$emit("close");
                    EventBus.$emit("reload:asset-stock", true);
                })
                .catch(() => {
                    this.$emit("error");
                })
                .finally(() => {
                    this.pageLoading = false;
                });
        },
    },
    mounted() {
        this.supplierList = this.localDB("suppliers", []);
    },
    computed: {
        ...mapGetters(["errors", "localDB"]),
    },
};
</script>
