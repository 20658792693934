<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md9 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				{{ getTitle() }}
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th width="15%" class="p-2 light-blue-bg">Quantity</th>
						<th width="15%" class="p-2 light-blue-bg">Salvage Unit</th>
						<th width="15%" class="p-2 light-blue-bg">Total Salvage</th>
						<th width="15%" class="p-2 light-blue-bg">Reason</th>
						<th width="30%" class="p-2 light-blue-bg">Comments</th>
					</tr>
				</thead>
				<tbody v-if="retirements.length">
					<tr v-for="(row, index) in retirements" :key="index">
						<td width="15%" class="p-2 border-top-light-grey">
							{{ row.quantity }}
						</td>
						<td width="15%" class="p-2 border-top-light-grey">
							{{ row.salvage_unit }}
						</td>
						<td width="15%" class="p-2 border-top-light-grey">
							{{ row.total_salvage }}
						</td>
						<td width="20%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="reason" label="reason"></ShowValue>
						</td>
						<td class="p-2 border-top-light-grey d-grid">
							<ShowValue :object="row" object-key="comment" truncate label="comments"></ShowValue>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="5">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no {{ getTitle() }} at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>
<script>
import { toSafeInteger } from "lodash";
import ApiService from "@/core/services/api.service";
import { EventBus } from "@/core/event-bus/event.bus";
import ShowValue from "@/view/components/ShowValue";

export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		uuid: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: "all",
		},
	},
	data() {
		return {
			statusList: [
				{
					text: "Checked Out",
					value: "checked-out",
				},
				{
					text: "Overdue",
					value: "overdue",
				},
			],
			status_filter: "checked-out",
			retirements: [],
			reservation: {},
			checkoutDialog: false,
			cancelDialog: false,
			pageLoading: true,
			approveLoading: false,
			cancelLoading: false,
		};
	},
	methods: {
		getTitle() {
			return "Retire Stocks";
		},
		getretirements() {
			let status = this.status;
			if (status == "checked-out" && this.status_filter) {
				status = this.status_filter;
			}
			ApiService.query(`${this.type}/${this.uuid}/retire-item`, { status })
				.then(({ data }) => {
					this.retirements = data;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		ShowValue,
	},
	mounted() {
		this.getretirements();

		EventBus.$on("reload:asset-reservation", () => {
			this.getretirements();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:asset-reservation");
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
