<template>
<div class="h-100">
    <v-layout class="p-4 border-bottom-light-grey min-height-57px">
        <v-flex md5 class="font-level-3-bold my-auto">
            <!-- <span class="detail-svg-icon mr-2">
                <inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
            </span> -->
            Stocks
        </v-flex>
        <v-flex md7 class="font-level-3-bold my-auto text-right">
            <v-btn color="blue darken-4 white--text" class="mr-2" depressed tile>Upload</v-btn>
            <v-btn color="blue darken-4 white--text" depressed tile @click="isAddStockDialog = true;">Add Stock</v-btn>
        </v-flex>
    </v-layout>

    <!-- Loading State -->
    <div style="height: calc(100vh - 385px);">
        <div v-if="isLoading" class="text-center py-6">
            <v-progress-circular indeterminate color="primary" size="64" />
        </div>

        <!-- Display Table -->
        <template v-else>
            <div class="overflow-y" style="max-height: calc(100vh - 430px)">
                <table width="100%" class=" detail-table table-head-sticky">
                    <thead>
                        <tr>
                            <th width="10%" class="p-2 light-blue-bg">Supplier</th>
                            <th width="10%" class="p-2 light-blue-bg">Quantity</th>
                            <th width="20%" class="p-2 light-blue-bg">Description</th>
                            <th width="10%" class="p-2 light-blue-bg">Created By</th>
                            <th width="10%" class="p-2 light-blue-bg">Created At</th>
                            <th width="1%" class="p-2 light-blue-bg">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(record, index) in assetRecords" :key="index">
                            <td class="p-2 border-top-light-grey">{{ record.supplier_name }}</td>
                            <td class="p-2 border-top-light-grey">{{ record.quantity }}</td>
                            <td class="p-2 border-top-light-grey">{{ record.description }}</td>
                            <td class="p-2 border-top-light-grey">{{ record.created_by }}</td>
                            <td class="p-2 border-top-light-grey">
                                <v-tooltip bottom content-class="custom-left-tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip v-bind="attrs" v-on="on" color="blue darken-4" text-color="white">
                                            {{ record.created_at }}
                                        </v-chip>
                                    </template>
                                    <span>{{ record.added_at }}</span>
                                </v-tooltip>
                            </td>
                            <td class="p-2 border-top-light-grey">
                                <v-list-item v-if="getPermission('asset-stock:delete')" @click="confirmDelete(record.uuid)">
                                    <v-list-item-title>
                                        <v-icon small left color="red darken-4">mdi-delete</v-icon>
                                    </v-list-item-title>
                                </v-list-item>
                            </td>
                        </tr>
                    </tbody>

                </table>

            </div>
            <!-- Pagination Component -->
            <div class="d-flex justify-between align-center py-3" style="position: absolute; bottom:-15px; width: 100%; left: 0; right: 0; background-color: #fff;">
                <div v-if="pagination && assetRecords.length > 0" class="font-level-3">
                    Showing
                    {{ (pagination.per_page * (currentPage - 1)) + 1 }}
                    to
                    {{ Math.min(pagination.per_page * currentPage, pagination.total_records) }}
                    of
                    {{ pagination.total_records }} rows
                </div>

                <!-- Pagination Component -->
                <v-pagination color="blue darken-4 white--text" v-if="pagination && assetRecords.length > 0" :length="pagination.total_pages" :total-visible="9" v-model="currentPage" @input="fetchAssetRecords(currentPage)" />
            </div>
        </template>
    </div>

    <AddStockDialog v-on:success="fetchAssetRecords" v-on:close="isAddStockDialog = false" :stock-dialog="isAddStockDialog" :typeUuid="typeUuid" />

    <!-- Confirmation Dialog -->
    <Dialog :dialog="isDeleteDialogVisible" :dialog-width="640">
        <template v-slot:title> Delete Stock </template>
        <template v-slot:body>
            <v-row class="delete-dialog">
                <p class="btx-p m-0">
                    <span class="text-capitalize">Stock</span>
                    will be deleted forever and cannot be retrieved later. Are you sure about deleting it?
                </p>

            </v-row>
        </template>
        <template v-slot:action>
            <v-btn class="white--text" :loading="deleteButton" :disabled="deleteButton" depressed color="red lighten-1" tile v-on:click="deleteRow">
                Yes! Delete
            </v-btn>
            <v-btn depressed tile :disabled="deleteButton" v-on:click="deleteClear"> No, Close </v-btn>
        </template>
    </Dialog>
</div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import AddStockDialog from "@/view/components/AddStockDialog";
import {
    EventBus
} from "@/core/event-bus/event.bus";
import Dialog from "@/view/components/Dialog";

export default {
    props: {
        typeUuid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            assetRecords: [],
            isAddStockDialog: false,
            isLoading: false,
            pagination: null,
            currentPage: 1,
            isDeleteDialogVisible: false,
            deleteRecordId: null,
            deleteText: '',
            deleteNote: '',
            deleteButton: false,
        };
    },
    mounted() {
        this.fetchAssetRecords();
    },
    methods: {
        fetchAssetRecords(page = 1) {
            // this.isLoading = true;
            ApiService.get(`asset-stock/${this.typeUuid}/record?page=${page}`)
                .then(({
                    data
                }) => {
                    this.assetRecords = data.data;
                    this.pagination = data.pagination;
                    this.currentPage = this.pagination.current_page;
                })
                .catch((error) => {
                    console.error("Error fetching asset records:", error);
                    this.$toast.error('Error fetching asset records');
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        confirmDelete(recordId) {
            this.deleteRecordId = recordId;
            this.deleteText = recordId; 
            this.deleteNote = 'This action cannot be undone.'; 
            this.isDeleteDialogVisible = true;
        },

        deleteRow() {
            this.deleteButton = true;
            ApiService.delete(`asset-stock/${this.typeUuid}/delete/${this.deleteRecordId}`)
                .then(() => {
                    this.fetchAssetRecords(this.currentPage);
                    EventBus.$emit("reload:asset-stock", true);
                })
                .catch((error) => {
                    console.error("Error deleting stock record:", error);
                })
                .finally(() => {
                    this.deleteButton = false;
                    this.isDeleteDialogVisible = false;
                });
        },

        deleteClear() {
            this.isDeleteDialogVisible = false;
        },
    },
    components: {
        AddStockDialog,
        Dialog, 
    },
};
</script>

<style scoped>
.justify-between {
    justify-content: space-between;
}
</style>
